#new-pkg .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 70px;
}

#new-pkg .ant-select-single .ant-select-selector .ant-select-selection-item,
#new-pkg .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 70px;
    font-size: 24px;
}

#new-pkg .ant-select {
    line-height: 5;
}

#new-pkg .ant-select-item-option-content {
    font-size: 24px;
    padding: 10px;
}

#new-pkg .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    font-size: 24px;
}

#new-pkg div.multi-value-display span:nth-child(2) {
    font-size: 18px;
}