img.footer-logo {
    width: 213px;
    height: 57px;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-clip: padding-box;
    margin: -16px 0;
}

.footer-row {
    text-align: center;
}

.fake-link {
    text-decoration: underline;
    cursor: pointer;
}
