.update-section-header .ant-page-header {
    padding-left: 0px;
    padding-right: 0px;
}

.update-section-header .ant-page-header-heading {
    background: var(--dcs-light-green) 0% 0% no-repeat padding-box;
    padding-left: 19px;
    width: 99%;
}

.update-section-header .sub-header,
#wait-time-form .sub-header {
    color: var(--dcs-dark-gray);
    text-align: left;
    font: normal normal 500 20px/28px Barlow;
    letter-spacing: 0px;
}

#testimonial-previews .preview-testimonial {
    padding: 20px 0;
}

#testimonial-previews .preview-number {
    color: var(--dcs-blue);
    font: normal normal 800 16px/24px Barlow;
}

#testimonial-previews .preview-author {
    font-weight: 500;
}

#testimonial-previews .preview-author::before {
    content: " - ";
}

#testimonial-previews .ant-typography-edit-content.preview-author::before {
    content: none;
}

#testimonial-previews .ant-divider-horizontal {
    border-top-color: var(--dcs-light-green);
    margin: 8px;
}

#wait-time-form .wait-time input {
    color: var(dcs-dark-gray);
    text-align: center;
    font: normal normal normal 28px/30px Heebo;
    letter-spacing: 0px;
    opacity: 1;
}

#wait-time-form .business-days {
    color: var(dcs-dark-gray);
    text-align: left;
    font: normal normal 300 20px/30px Barlow;
    letter-spacing: 0px;
    margin-left: 10px;
    line-height: 36px;
}

#homepage-facebook-form .settings-fb-logo {
    margin-right: 20px;
    font-size: 57px;
    color: var(--dcs-blue);
}

#homepage-facebook-form .settings-fb-button {
    margin-top: 20px;
}

#homepage-facebook-form .facebook-form-item {
    margin-bottom: 2px;
}

form.website-update .result-success {
    padding-left: 10px;
    color: var(--dcs-dark-green);
    font-size: 20px;
}

.ach-account-mgr .ant-select {
    width: 281px;
}