dt {
    float: left;
    clear: left;
    padding-right: 0.3em;
}

dt::after {
    content: " - ";
}

.actions-container {
    margin-bottom: 17px;
}
