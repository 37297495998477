div.multi-value-display span:nth-child(1) {
    color: var(--dcs-dark-gray);
    opacity: 1;
    display: flex;
}

div.multi-value-display span:nth-child(2) {
    color: var(--dcs-gray);
    font-size: 12px;
    opacity: 1;
}
