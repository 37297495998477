div.manual-adjustment-modal .ant-modal-header {
    background-color: var(--dcs-light-grey);
}

div.manual-adjustment-modal .ant-modal-title {
    color: var(--dcs-dark-grey);
    font: normal normal normal 36px/32px Barlow;
    letter-spacing: 0px;
}

div#adjustment-type .ant-radio-button-wrapper-checked.adj-credit {
    background-color: var(--dcs-dark-green);
    color: var(--dcs-white);
}

div#adjustment-type .ant-radio-button-wrapper-checked.adj-debit {
    background-color: var(--dcs-error-red);
    color: var(--dcs-white);
}

div#confirm-banner {
    color: var(--dcs-dark-green);
    font: normal normal normal 28px/32px Barlow;
    margin-bottom: 17px;
}

div#confirm-message div.positive,
div#confirm-message div.negative,
div#adjustment-result div.positive,
div#adjustment-result div.negative {
    display: inline-block;
}

div#confirm-message div.content {
    margin-bottom: 1em;
}

div#adjustment-result {
    text-align: center;
}

div#adjustment-result div.result {
    font-size: 60px;
    margin-bottom: 22px;
}

div#adjustment-result div.result.success {
    color: var(--dcs-dark-green);
}

div#adjustment-result div.result.error {
    color: var(--dcs-error-red);
}

div#adjustment-result div.content {
    font: normal normal normal 24px/32px Heebo;
}

span.transaction-creator {
    margin-left: 50px;
}