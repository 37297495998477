/* 
    can't use pseudo selectors in inline styles,
    and an all-caps placeholder bugs me.
*/

.initials-override {
    text-transform: uppercase;
}

.initials-override::placeholder {
    text-transform: none;
}