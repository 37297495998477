.customer-id {
    color: var(--dcs-white);
    text-align: right;
    font: normal normal bold 16px/28px Barlow;
}

.customer-info-container {
    background: var(--dcs-blue) 0% 0% no-repeat padding-box;
    padding: 10px 0 20px 0;
}

.customer-info-box>div {
    background: var(--dcs-white) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    height: 100%;
}

.customer-info-box .ant-row:nth-child(1) {
    height: 25px;
}

.customer-info-box h2 {
    color: var(--dcs-dark-gray);
    text-align: left;
    font: normal normal normal 24px/32px Barlow;
}

.customer-info-box p {
    color: var(--dcs-dark-gray);
    text-align: left;
    font: normal normal normal 14px/26px Barlow;
    margin: 0;
    min-height: 3rem;
}

.customer-info-box h1 {
    color: var(--dcs-dark-gray);
    text-align: left;
    font: normal normal bold 36px/32px Barlow;
    border-bottom: 3px solid var(--dcs-light-green);
    margin: 12px 0;
    padding: 0 0 8px 0;
}

.customer-info-box a {
    color: var(--dcs-blue);
    text-align: left;
    text-decoration: underline;
    font: normal normal 600 14px/26px Barlow;
}