/*
remove the 3 imports below and use the below imports for the new fonts
*/
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --dcs-black: #22272c;
    --dcs-bright-blue: #1573e5;
    --dcs-light-blue: #c2f0fa;
    --dcs-dark-blue: #003574;
    --dcs-white: #ffffff;

    --dcs-dark-green: #06954d;
    --dcs-light-green: #c7f4c5;
    --dcs-light-gray: #f0f4fb;
    --dcs-dark-gray-transparent: #22272c99;
    --dcs-gray: #707070;
    --dcs-dark-gray: var(--dcs-black);

    --dcs-blue: var(--dcs-bright-blue);
    --ant-active-filter: #1890ff;
    --dcs-error-red: #d94141;
    --dcs-warning: #faad14;
}

body {
    font-family: "Barlow", "Regular";
}

h1 {
    font: normal normal 500 42px/42px Barlow;
}

h2 {
    font: normal normal normal 36px/36px Barlow;
}

h3 {
    font: normal normal normal 36px/36px Barlow;
}

h4 {
    font: normal normal bold 24px/24px Barlow;
}

h5 {
    font: normal normal normal 20px/20px Barlow;
}

.site-container {
    min-height: 85vh;
    background-color: var(--dcs-white);
}

.dashboard-content {
    padding: 50px;
}

.positive {
    color: var(--dcs-dark-green);
    font-weight: bold;
}

.negative {
    color: var(--dcs-error-red);
    font-weight: bold;
}

.flat {
    color: #000;
    font-weight: bold;
}

.public-layout .ant-layout-header {
    padding: 0;
}

.public-layout .wait-times {
    display: flex;
    height: 100%;
    background-color: var(--dcs-blue);
    align-items: center;
    justify-content: center;
}

.primary-button {
    min-height: 38px;
    background-color: var(--dcs-blue);
    border: 0;
    border-radius: 4px;
    color: var(--dcs-white);
    font: normal normal 600 18px/22px Barlow;
}

.bold-font {
    font-weight: 500;
}

#impersonation-banner {
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: var(--dcs-light-gray);
    min-height: 100px;
    padding-left: 68px;
    padding-right: 68px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border: 10px solid var(--dcs-error-red);
}

#impersonation-banner .viewing-as {
    font: normal normal bold 33px/32px Heebo;
    color: var(--dcs-error-red);
    text-transform: uppercase;
}

.zendesk-html p {
    margin: 10px 0 0 0;
}

.zendesk-html table {
    margin: 10px 0 0 0;
}

.zendesk-html ul {
    padding: 0 0 0 20px;
}

.ant-table-thead tr th.ant-table-cell {
    background-color: var(--dcs-blue);
    color: var(--dcs-white);
}

.ant-table-filter-trigger.active {
    color: var(--dcs-dark-blue);
}

.anticon.anticon-caret-up.active {
    color: var(--dcs-dark-blue);
}

.anticon.anticon-caret-down.active {
    color: var(--dcs-dark-blue);
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
    color: var(--dcs-black);
}

.ant-pagination-options {
    color: var(--dcs-white);
}

.initials-override {
    text-transform: uppercase;
}

.initials-override::placeholder {
    text-transform: none;
}

.no-radio .ant-radio {
    display: none !important;
}

.ant-radio-wrapper-checked .rate-card {
    box-shadow: 0px 0px 5px 3px rgba(from var(--dcs-blue) r g b / 0.5);
}