div.custom-filter .ant-select {
    min-width: 190px;
}

div.custom-filter .selector-wrapper {
    display: block;
    padding: 12px;
}

div.table-filter-actions-single {
    display: flex;
    justify-content: flex-end;
}

div.table-filter-actions-multiple {
    display: flex;
    justify-content: space-between;
}

div.has-hidden-input {
    display: none;
}

div.card-edit-modal .ant-modal-header {
    background-color: var(--dcs-light-gray);
}

div.card-edit-modal .ant-modal-title {
    color: var(--dcs-dark-gray);
    font: normal normal normal 36px/32px Barlow;
    letter-spacing: 0px;
}

div#staff-card-table .anticon-filter.active {
    color: var(--ant-active-filter);
}

div#staff-card-table .ant-table-cell-row-hover {
    background-color: var(--dcs-light-gray);
}

div#staff-card-table .recently-updated {
    background: var(--dcs-light-green);
}

div#actions-and-filter {
    padding-bottom: 5px;
    line-height: 32px;
}

div#actions-and-filter #bulk-edit-button {
    background-color: var(--dcs-dark-green);
    color: var(--dcs-white);
}

div#staff-card-active-filters span {
    font-size: 16px;
}

div#staff-card-active-filters .ant-tag-close-icon {
    color: var(--dcs-error-red);
}
